import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, message, Row, Select } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getDentistas } from '../services/UserServices';
import { getAvailableHours, registerAppointment } from '../services/AppointmentServices';
import '../assets/styles/Agendar.css';
import { useUserId } from '../utils/Cookies';
import { getProcedures } from '../services/ProceduresServices';

const { Option } = Select;

const Agendar = () => {
    const [horariosDisponibles, setHorariosDisponibles] = useState([]);
    const [tratamientoSeleccionado, setTratamientoSeleccionado] = useState('');
    const [especialistaSeleccionado, setEspecialistaSeleccionado] = useState('');
    const [especialistaId, setEspecialistaId] = useState('');
    const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
    const [horarioSeleccionado, setHorarioSeleccionado] = useState('');
    const [formValido, setFormValido] = useState(false);
    const [placeholderDate, setPlaceholderDate] = useState('Seleccione la fecha');
    const [placeholderTime, setPlaceholderTime] = useState('Seleccione la hora');
    const [dentistas, setDentistas] = useState([]);
    const [filteredDentistas, setFilteredDentistas] = useState([]);
    const [tratamientos, setTratamientos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [procedimientos, setProcedimientos] = useState([]);
    const [procedimientoSeleccionado, setProcedimientoSeleccionado] = useState('');
    const clienteId = useUserId();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDentists = async () => {
            try {
                const data = await getDentistas();
                setDentistas(data);
            } catch (error) {
                console.error('Error fetching dentists:', error);
            }
        };

        fetchDentists();
    }, []);

    useEffect(() => {
        const isValid = validarFormulario();
        setFormValido(isValid);
    }, [tratamientoSeleccionado, especialistaSeleccionado, fechaSeleccionada, horarioSeleccionado]);

    useEffect(() => {
        const fetchTreatments = async () => {
            try {
                const data = await getProcedures();
                if (Array.isArray(data.procedures)) {
                    const uniqueCategories = Array.from(new Set(data.procedures.map(tratamiento => tratamiento.categoria)));
                    setCategorias(uniqueCategories);
                    setTratamientos(data.procedures);
                } else {
                    console.error('Formato de datos inesperado:', data);
                }
            } catch (error) {
                console.error('Error fetching treatments:', error);
                message.error('Error al cargar tratamientos. Por favor, intente nuevamente más tarde.');
                // Limpiar el campo de selección de tratamientos en caso de error
                setCategorias([]);
                setTratamientos([]);
            }
        };

        fetchTreatments();
    }, []);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 630) {
                setPlaceholderDate('Fecha');
                setPlaceholderTime('Hora');
            } else {
                setPlaceholderDate('Seleccione la fecha');
                setPlaceholderTime('Seleccione la hora');
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generarHorariosDisponibles = async (specialistId, date) => {
        const availableHours = await getAvailableHours(specialistId, date);

        const formattedAvailableHours = availableHours.map(times => {
            const [start, end] = times;
            return `${start} - ${end}`;
        });

        setHorariosDisponibles(formattedAvailableHours);
    };




    const validarFormulario = () => {
        return tratamientoSeleccionado && especialistaSeleccionado && fechaSeleccionada && horarioSeleccionado;
    };

    const disabledDate = (current) => {
        const today = moment().startOf('day');
        const twoMonthsFromToday = moment().add(2, 'months').endOf('day');
        return current && (current < today || current > twoMonthsFromToday || current.day() === 0);
    };
    const handleTratamientoChange = (value) => {
        setTratamientoSeleccionado(value);

        // Filtrar los procedimientos basados en la categoría seleccionada
        const procedimientosFiltrados = tratamientos.filter(tratamiento => tratamiento.categoria === value);
        setProcedimientos(procedimientosFiltrados);

        // Filtrar los dentistas que ofrecen esa categoría de tratamiento
        const filtered = dentistas.filter(dentista =>
            Array.isArray(dentista.treatments) &&
            dentista.treatments.some(treatment => treatment.name === value)
        );
        setFilteredDentistas(filtered);

        // Resetear los valores seleccionados
        setEspecialistaSeleccionado('');
        setEspecialistaId('');
        setFechaSeleccionada(null);
        setHorarioSeleccionado('');
        setHorariosDisponibles([]);
    };


    const handleEspecialistaChange = async (value) => {
        setEspecialistaSeleccionado(value);
        setFechaSeleccionada(null); 
        setHorariosDisponibles([]); 
    };

    const handleDateChange = async (date) => {
        setFechaSeleccionada(date);
        setHorarioSeleccionado('');
        setHorariosDisponibles([]);

        if (date && especialistaSeleccionado) {
            try {
                await generarHorariosDisponibles(especialistaSeleccionado, date.format('YYYY-MM-DD'));
            } catch (error) {
                console.error('Error fetching working hours:', error);
                message.error(`No hay horarios disponibles para ese día`);
                setHorariosDisponibles([]);
            }
        }
    };

    const handleAgendarClick = async () => {
        if (formValido) {
            try {
                const procedimiento = procedimientos.find(proc => proc._id === procedimientoSeleccionado);
                const nombreProcedimiento = procedimiento ? procedimiento.nombre : '';

                await registerAppointment(
                    nombreProcedimiento, 
                    especialistaSeleccionado,
                    fechaSeleccionada.format('YYYY-MM-DD'),
                    horarioSeleccionado,
                    clienteId
                );
                message.success('Cita registrada con éxito');
                setTratamientoSeleccionado('');
                setProcedimientoSeleccionado('');
                setEspecialistaSeleccionado('');
                setEspecialistaId('');
                setFechaSeleccionada(null);
                setHorarioSeleccionado('');
                setHorariosDisponibles([]);
                navigate('/app/cliente/citas');
            } catch (error) {
                message.error('Parece que ese horario ya esta ocupado');
                console.error('Error registering appointment:', error);
            }
        } else {
            message.warning('Por favor complete todos los campos');
        }
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="agendarCard">
                    <Row>
                        <h1 className="home-title">Agenda aquí tu próxima cita</h1>
                        <p className="under-title">Ingresa toda la información requerida para poder obtener tu cita a
                            continuación.</p>
                    </Row>
                    <Row className="inputsRow">
                        <Col span={24} className="inputCol input-group">
                            <Select
                                showSearch
                                placeholder="Tratamiento"
                                className="selectInput"
                                value={tratamientoSeleccionado || undefined}
                                onChange={handleTratamientoChange}
                                disabled={categorias.length === 0} 
                            >
                                {categorias.length > 0 ? (
                                    categorias.map(categoria => (
                                        <Option key={categoria} value={categoria}>
                                            {categoria}
                                        </Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay categorías disponibles</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <Select
                                showSearch
                                placeholder="Procedimiento"
                                className="selectInput"
                                value={procedimientoSeleccionado || undefined}
                                onChange={(value) => setProcedimientoSeleccionado(value)}
                                disabled={procedimientos.length === 0}
                            >
                                {procedimientos.length > 0 ? (
                                    procedimientos.map(procedimiento => (
                                        <Option key={procedimiento._id} value={procedimiento._id}>
                                            {procedimiento.nombre}
                                        </Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay procedimientos disponibles</Option>
                                )}
                            </Select>
                        </Col>

                        <Col span={24} className="inputCol">
                            <Select
                                showSearch
                                placeholder="Especialista"
                                className="selectInput"
                                value={especialistaSeleccionado || undefined}
                                onChange={handleEspecialistaChange}
                                disabled={filteredDentistas.length === 0} 
                            >
                                {filteredDentistas.length > 0 ? (
                                    filteredDentistas.map(dentista => (
                                        <Option key={dentista._id} value={dentista._id}>{dentista.username}</Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay especialistas disponibles</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <DatePicker
                                placeholder={placeholderDate}
                                className="selectInput"
                                value={fechaSeleccionada || undefined}
                                onChange={handleDateChange}
                                disabledDate={disabledDate}
                                disabled={!especialistaSeleccionado} 
                            />
                        </Col>
                        <Col span={24} className="inputCol">
                            <Select
                                placeholder={placeholderTime}
                                className="selectInput"
                                disabled={horariosDisponibles.length === 0 || !fechaSeleccionada}
                                value={horarioSeleccionado}
                                onChange={(value) => setHorarioSeleccionado(value)}
                            >
                                {horariosDisponibles.length > 0 ? (
                                    horariosDisponibles.map((horario, index) => (
                                        <Option key={index} value={horario}>
                                            {horario}
                                        </Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay horarios disponibles</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <Button className="agendarButton" disabled={!formValido} onClick={handleAgendarClick}>
                                AGENDAR
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Agendar;

import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import '../assets/styles/AppHomePage.css';
import { useUser } from '../utils/Cookies';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../services/UserServices';
import { getAppointmentsByDay } from '../services/AppointmentServices';

const formatDateWithDay = (date) => {
    const daysOfWeekSpanish = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const dayOfWeek = daysOfWeekSpanish[date.getDay()];
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    return {
        displayDate: `${day}/${month}/${year}`,
        dayOfWeek: dayOfWeek,
        urlDate: `${year}-${month}-${day}`
    };
};

const AppHomePage = () => {
    const navigate = useNavigate();
    const user = useUser();
    const [appointments, setAppointments] = useState([]);

    const getUserRoleContent = () => {
        if (!user) {
            return {};
        }

        const { displayDate, dayOfWeek, urlDate } = formatDateWithDay(new Date());

        switch (user.role) {
            case 'cliente':
                return {
                    welcomeMessage: `Hola ${user.username}, Bienvenido a SmileUp.`,
                    subTitle: "Aquí podrás agendar tus nuevas citas y también gestionar las futuras.",
                    agendaTitle: "Agenda tu próxima cita:",
                    agendaButton: "Agendar Cita +",
                    historyTitle: "Historial clínico:",
                    historySubTitle: "Datos de todas tus citas",
                    historyButton: "Ver +",
                    nextAppointmentsTitle: "Tus próximas citas:",
                    nextAppointmentsSubTitle: "Actualmente no tienes ninguna cita reservada",
                    manageAppointmentsButton: "Gestionar Citas",
                    agendaLink: "./cliente/agendar",
                    historyLink: "./cliente/historial",
                    manageLink: "./cliente/citas"
                };
            case 'dentista':
                return {
                    welcomeMessage: `Hola ${user.username}, Bienvenido a SmileUp.`,
                    subTitle: "Aquí podrás ver tus futuras citas y clientes, como también podrás gestionarlas.",
                    agendaTitle: "historial de citas:",
                    agendaButton: "ver",
                    historyTitle: "Calendario:",
                    historySubTitle: "Ver futuras citas y gestionarlas.",
                    historyButton: "Ver +",
                    nextAppointmentsTitle: "Tus proximas citas:",
                    nextAppointmentsSubTitle: "Aquí podras ver tus proximas citas.",
                    manageAppointmentsButton: "Gestionar Citas",
                    agendaLink: "./dentista/historial",
                    historyLink: "./dentista/calendario",
                    manageLink: "./dentista/gestionar",
                    accountsButton: "Citas del día",
                accountsLink: `./dentista/calendario/${urlDate}`
                };
            case 'admin':
                return {
                    welcomeMessage: `Hola ${user.username}, Bienvenido a SmileUp.`,
                    subTitle: "Aquí podrás gestionar el sistema de manera completa.",
                    agendaTitle: "Clientes, Citas y Procedimientos:",
                    agendaButton: "Ver +",
                    historyTitle: "Calendario:",
                    historySubTitle: "Ver futuras citas y gestionarlas.",
                    historyButton: "Ver +",
                    nextAppointmentsTitle: "Dentistas y Secretarios:",
                    nextAppointmentsSubTitle: "Acceda a la modificacion de los dentistas y secretarios.",
                    manageAppointmentsButton: "Gestionar",
                    agendaLink: "./admin/inspeccionar",
                    historyLink: "./admin/calendario",
                    manageLink: "./admin/gestionar",
                    accountsButton: "Ver Cuentas",
                    accountsLink: "./admin/contaduria"
                };
            case 'secretaria':
                return {
                    welcomeMessage: `Hola ${user.username}, Bienvenido a SmileUp.`,
                    subTitle: "Aquí podrás gestionar las citas y la agenda.",
                    agendaTitle: "Gestionar Citas:",
                    agendaButton: "Gestionar",
                    historyTitle: "Calendario:",
                    historySubTitle: "Ver y gestionar el calendario.",
                    historyButton: "Ver +",
                    nextAppointmentsTitle: `Citas del dia ${dayOfWeek} ${displayDate}`,
                    nextAppointmentsSubTitle: "Aquí puedes ver todas las citas de hoy.",
                    manageAppointmentsButton: "Ver +",
                    agendaLink: "./secretaria/citas",
                    historyLink: "./secretaria/calendario",
                    manageLink: `./secretaria/calendario/${urlDate}`,
                    accountsButton: "Dar cita",
                    accountsLink: "./secretaria/verificar"
                };
            default:
                return {};
        }
    };

    const roleContent = getUserRoleContent();

    useEffect(() => { }, [roleContent]);

    useEffect(() => {
        if (user && user.role === 'secretaria') {
            const fetchAppointments = async () => {
                const currentDate = new Date();
                const { urlDate } = formatDateWithDay(currentDate);

                try {
                    const fetchedAppointments = await getAppointmentsByDay(urlDate);
                    const appointmentsWithUserDetails = await Promise.all(fetchedAppointments.map(async (appointment) => {
                        const cliente = await getUser(appointment.participants[0]);
                        const especialista = await getUser(appointment.participants[1]);
                        return {
                            ...appointment,
                            cliente: cliente.username,
                            especialista: especialista.username
                        };
                    }));

                    setAppointments(appointmentsWithUserDetails);
                } catch (error) {
                    console.error("Error fetching appointments", error);
                }
            };

            fetchAppointments();
        }
    }, []);

    const handleRedirect = (path) => {
        navigate(path);
    };

    return (
        <div className="home-client">
            <Row justify="center" className="cardsRow">
                <Col xl={9} sm={13} xs={20}>
                    <Row justify="center">
                        <Col xl={24} sm={24} xs={24} className="cardUpLeftCol">
                            <h1 className="home-title">{roleContent.welcomeMessage}</h1>
                            <p className="under-title">{roleContent.subTitle}</p>
                            {(user.role === 'admin' || user.role === 'secretaria'|| user.role === 'dentista') && (
                                <Button className="verCuentas" onClick={() => handleRedirect(roleContent.accountsLink)}>
                                    {roleContent.accountsButton}
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <Col xl={11} sm={11} xs={24} className="cardDownLeftCol">
                            <h1 className="home-title">{roleContent.agendaTitle}</h1>
                            <Button className="gestionarCita" onClick={() => handleRedirect(roleContent.agendaLink)}>
                                {roleContent.agendaButton}
                            </Button>
                        </Col>
                        <Col xl={11} sm={11} xs={24} className="cardDownLeftCol">
                            <h1 className="home-title">{roleContent.historyTitle}</h1>
                            <p className="under-title">{roleContent.historySubTitle}</p>
                            <Button className="verHistorial" onClick={() => handleRedirect(roleContent.historyLink)}>
                                {roleContent.historyButton}
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xl={4} sm={13} xs={20}>
                    <Row justify="center">
                        <Col xl={24} sm={24} xs={24} className="cardRightCol">
                            <h1 className="home-title">{roleContent.nextAppointmentsTitle}</h1>
                            {user.role === 'secretaria' && appointments.length > 0 ? (
                                <div className='scroll'>
                                    {appointments.map((appointment) => {
                                        const timestart = new Date(appointment.date.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                        const timeend = new Date(appointment.date.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                        const tratamiento = appointment.treatment;
                                        const estado = appointment.status;

                                        return (
                                            <div key={appointment._id} className="appointment-item">
                                                {timestart} - {timeend} - {tratamiento} - {appointment.cliente} - {appointment.especialista} - {estado}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <p className="under-title">{roleContent.nextAppointmentsSubTitle}</p>
                            )}
                            <Button className="gestionarCita" onClick={() => handleRedirect(roleContent.manageLink)}>
                                {roleContent.manageAppointmentsButton}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AppHomePage;
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, message, Row, Select } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { getDentistas } from '../services/UserServices';
import { getAvailableHours, modifyAppointment, getAppointmentById } from '../services/AppointmentServices';
import '../assets/styles/Agendar.css';
import { useUserId } from '../utils/Cookies';
import { getProcedures } from '../services/ProceduresServices';

const { Option } = Select;

const AppointmentModify = () => {
    const [horariosDisponibles, setHorariosDisponibles] = useState([]);
    const [tratamientoSeleccionado, setTratamientoSeleccionado] = useState('');
    const [especialistaSeleccionado, setEspecialistaSeleccionado] = useState('');
    const [especialistaId, setEspecialistaId] = useState('');
    const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
    const [horarioSeleccionado, setHorarioSeleccionado] = useState('');
    const [formValido, setFormValido] = useState(false);
    const [placeholderDate, setPlaceholderDate] = useState('Seleccione la fecha');
    const [placeholderTime, setPlaceholderTime] = useState('Seleccione la hora');
    const [dentistas, setDentistas] = useState([]);
    const [filteredDentistas, setFilteredDentistas] = useState([]);
    const [tratamientos, setTratamientos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [procedimientos, setProcedimientos] = useState([]);
    const [procedimientoSeleccionado, setProcedimientoSeleccionado] = useState('');
    const navigate = useNavigate();
    const { appointmentId } = useParams();

    useEffect(() => {
        const fetchDentists = async () => {
            try {
                const data = await getDentistas();
                setDentistas(data);
            } catch (error) {
                console.error('Error fetching dentists:', error);
            }
        };

        fetchDentists();
    }, []);

    useEffect(() => {
        const isValid = validarFormulario();
        setFormValido(isValid);
    }, [tratamientoSeleccionado, especialistaSeleccionado, fechaSeleccionada, horarioSeleccionado]);

    useEffect(() => {
        const fetchTreatments = async () => {
            try {
                const data = await getProcedures();
                if (Array.isArray(data.procedures)) {
                    const uniqueCategories = Array.from(new Set(data.procedures.map(tratamiento => tratamiento.categoria)));
                    setCategorias(uniqueCategories);
                    setTratamientos(data.procedures);
                } else {
                    console.error('Formato de datos inesperado:', data);
                }
            } catch (error) {
                console.error('Error fetching treatments:', error);
                message.error('Error al cargar tratamientos. Por favor, intente nuevamente más tarde.');
                setCategorias([]);
                setTratamientos([]);
            }
        };

        fetchTreatments();
    }, []);

    /*
    useEffect(() => {
        const fetchAppointmentDetails = async () => {
            try {
                const appointment = await getAppointmentById(appointmentId);
                // Buscar el procedimiento basado en el nombre que viene en appointment
                const procedimientoEncontrado = tratamientos.find(procedimiento =>
                    procedimiento.nombre.trim() === appointment.treatment.trim()
                );

                if (procedimientoEncontrado) {
                    // Setear categoría y procedimiento basados en lo encontrado
                    setTratamientoSeleccionado(procedimientoEncontrado.categoria);
                    setProcedimientoSeleccionado(procedimientoEncontrado._id);

                    // Filtrar procedimientos por categoría
                    const procedimientosFiltrados = tratamientos.filter(tratamiento =>
                        tratamiento.categoria === procedimientoEncontrado.categoria
                    );
                    setProcedimientos(procedimientosFiltrados);
                } else {
                    console.error('No se encontró el procedimiento correspondiente en los datos.');
                }

                // Buscar el especialista basado en el ID o nombre
                const especialistaEncontrado = dentistas.find(dentista => dentista._id === appointment.participants[1]);

                if (especialistaEncontrado) {
                    setEspecialistaSeleccionado(especialistaEncontrado._id);  // O dentista.username si es por nombre
                } else {
                    console.error('No se encontró el especialista correspondiente.');
                }

                // Asignar otros valores
                setFechaSeleccionada(moment(appointment.date.day));

                const startTime = moment(appointment.date.start_time).format('HH:mm');
                const endTime = moment(appointment.date.end_time).format('HH:mm');
                setHorarioSeleccionado(`${startTime} - ${endTime}`);
            } catch (error) {
                console.error('Error fetching appointment details:', error);
                message.error('Error al cargar los detalles de la cita.');
            }
        };

        // Esperar a que los dentistas y tratamientos estén cargados
        if (dentistas.length > 0 && tratamientos.length > 0) {
            fetchAppointmentDetails();
        }
    }, [appointmentId, tratamientos, dentistas]);*/


    const handleModifyClick = async () => {
        if (formValido) {
            try {
                const procedimiento = procedimientos.find(proc => proc._id === procedimientoSeleccionado);
                const nombreProcedimiento = procedimiento ? procedimiento.nombre : '';

                if (!horarioSeleccionado.includes(" - ")) {
                    message.error('El formato del horario no es válido');
                    return;
                }

                await modifyAppointment(
                    appointmentId,
                    nombreProcedimiento,
                    especialistaSeleccionado,
                    fechaSeleccionada.format('YYYY-MM-DD'),
                    horarioSeleccionado,
                );

                message.success('Cita modificada con éxito');
                navigate(-1);
            } catch (error) {
                message.error('Parece que ese horario ya esta ocupado');
                console.error('Error modifying appointment:', error);
            }
        } else {
            message.warning('Por favor complete todos los campos');
        }
    };

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 630) {
                setPlaceholderDate('Fecha');
                setPlaceholderTime('Hora');
            } else {
                setPlaceholderDate('Seleccione la fecha');
                setPlaceholderTime('Seleccione la hora');
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generarHorariosDisponibles = async (specialistId, date) => {
        const availableHours = await getAvailableHours(specialistId, date);

        const formattedAvailableHours = availableHours.map(times => {
            const [start, end] = times;
            return `${start} - ${end}`;
        });

        setHorariosDisponibles(formattedAvailableHours);
    };

    const validarFormulario = () => {
        return tratamientoSeleccionado && especialistaSeleccionado && fechaSeleccionada && horarioSeleccionado;
    };

    const disabledDate = (current) => {
        const today = moment().startOf('day');
        const twoMonthsFromToday = moment().add(2, 'months').endOf('day');
        return current && (current < today || current > twoMonthsFromToday || current.day() === 0);
    };
    const handleTratamientoChange = (value) => {
        setTratamientoSeleccionado(value);

        const procedimientosFiltrados = tratamientos.filter(tratamiento => tratamiento.categoria === value);
        setProcedimientos(procedimientosFiltrados);

        const filtered = dentistas.filter(dentista =>
            Array.isArray(dentista.treatments) &&
            dentista.treatments.some(treatment => treatment.name === value)
        );
        setFilteredDentistas(filtered);

        setEspecialistaSeleccionado('');
        setEspecialistaId('');
        setFechaSeleccionada(null);
        setHorarioSeleccionado('');
        setHorariosDisponibles([]);
    };


    const handleEspecialistaChange = async (value) => {
        setEspecialistaSeleccionado(value);
        setFechaSeleccionada(null);
        setHorariosDisponibles([]); // Limpia los horarios disponibles
    };

    const handleDateChange = async (date) => {
        setFechaSeleccionada(date);
        setHorarioSeleccionado('');
        setHorariosDisponibles([]);

        if (date && especialistaSeleccionado) {
            try {
                await generarHorariosDisponibles(especialistaSeleccionado, date.format('YYYY-MM-DD'));
            } catch (error) {
                console.error('Error fetching working hours:', error);
                message.error(`No hay horarios disponibles para ese día`);
                setHorariosDisponibles([]);
            }
        }
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="agendarCard">
                    <Row>
                        <h1 className="home-title">Modifica aquí la cita</h1>
                        <p className="under-title">Ingresa toda la información requerida para poder realizar la modificacion a continuación.</p>
                    </Row>
                    <Row className="inputsRow">
                        <Col span={24} className="inputCol input-group">
                            <Select
                                showSearch
                                placeholder="Tratamiento"
                                className="selectInput"
                                value={tratamientoSeleccionado || undefined}
                                onChange={handleTratamientoChange}
                                disabled={categorias.length === 0}
                            >
                                {categorias.length > 0 ? (
                                    categorias.map(categoria => (
                                        <Option key={categoria} value={categoria}>
                                            {categoria}
                                        </Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay categorías disponibles</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <Select
                                showSearch
                                placeholder="Procedimiento"
                                className="selectInput"
                                value={procedimientoSeleccionado || undefined}
                                onChange={(value) => setProcedimientoSeleccionado(value)}
                                disabled={procedimientos.length === 0}
                            >
                                {procedimientos.length > 0 ? (
                                    procedimientos.map(procedimiento => (
                                        <Option key={procedimiento._id} value={procedimiento._id}>
                                            {procedimiento.nombre}
                                        </Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay procedimientos disponibles</Option>
                                )}
                            </Select>
                        </Col>

                        <Col span={24} className="inputCol">
                            <Select
                                showSearch
                                placeholder="Especialista"
                                className="selectInput"
                                value={especialistaSeleccionado || undefined}
                                onChange={handleEspecialistaChange}
                                disabled={filteredDentistas.length === 0}
                            >
                                {filteredDentistas.length > 0 ? (
                                    filteredDentistas.map(dentista => (
                                        <Option key={dentista._id} value={dentista._id}>{dentista.username}</Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay especialistas disponibles</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <DatePicker
                                placeholder={placeholderDate}
                                className="selectInput"
                                value={fechaSeleccionada || undefined}
                                onChange={handleDateChange}
                                disabledDate={disabledDate}
                                disabled={!especialistaSeleccionado} // Deshabilitar inicialmente
                            />
                        </Col>
                        <Col span={24} className="inputCol">
                            <Select
                                placeholder={placeholderTime}
                                className="selectInput"
                                disabled={horariosDisponibles.length === 0 || !fechaSeleccionada}
                                value={horarioSeleccionado}
                                onChange={(value) => setHorarioSeleccionado(value)}
                            >
                                {horariosDisponibles.length > 0 ? (
                                    horariosDisponibles.map((horario, index) => (
                                        <Option key={index} value={horario}>
                                            {horario}
                                        </Option>
                                    ))
                                ) : (
                                    <Option disabled>No hay horarios disponibles</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <Button
                                type="primary"
                                shape="round"
                                className="agendarButton"
                                onClick={() => navigate(-1)}
                            >
                                Volver
                            </Button>
                            <Button className="agendarButton" disabled={!formValido} onClick={handleModifyClick}>
                                MODIFICAR
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AppointmentModify;

import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getClientes } from '../services/UserServices';
import '../assets/styles/ClientsManage.css'

const ClientsManage = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState([]); // Aseguramos que sea un array vacío inicialmente
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const fetchedClients = await getClientes();
                setClients(fetchedClients || []); // Si fetchedClients es null, lo inicializamos como un array vacío
            } catch (error) {
                console.error('Error fetching clients:', error);
                setClients([]); // En caso de error, dejamos clients como un array vacío
            }
        };
        fetchClients();
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    };

    const filteredClients = clients.filter(client =>
        client.NIE?.toLowerCase().includes(searchText.toLowerCase()) ||
        client.username?.toLowerCase().includes(searchText.toLowerCase()) ||
        client.email?.toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        {
            title: 'ID',
            dataIndex: 'NIE',
            key: 'NIE',
            width: 60,
        },
        {
            title: 'Usuario',
            dataIndex: 'username',
            key: 'username',
            width: 140,
        },
        {
            title: 'Telefono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 110,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
        },
    ];

    const navigateToHome = () => {
        navigate(`/app`);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col xl={14} lg={18} sm={22} xs={22} className="clientsCard">
                    <Row>
                        <h1 className="home-title paddleft">Estos son tus clientes</h1>
                    </Row>
                    <Row>
                        <Input
                            placeholder="Buscar"
                            className='search'
                            value={searchText}
                            onChange={handleSearch}
                            style={{ marginBottom: 20 }}
                        />
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={filteredClients}
                        className="tabla font-small"
                        pagination={{
                            current: currentPage,
                            pageSize: 10,
                            onChange: page => setCurrentPage(page),
                        }}
                        scroll={{ y: 240 }}
                        rowKey="_id"
                    />

                    <Row>
                        <Col className="volver">
                            <Button className="volverButton2" onClick={navigateToHome}>
                                VOLVER
                            </Button>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </div>
    );
};

export default ClientsManage;

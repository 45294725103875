import Cookies from "universal-cookie";

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const registerAppointment = async (tratamiento, especialista, fecha, horario, clienteId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    const [startTime, endTime] = horario.split(" - ");

    // Function to get the ISO string with the local time zone offset
    const toLocalISOString = (date, time) => {
        const fullDatetimeString = `${date}T${time}:00`;
        const datetime = new Date(fullDatetimeString);
        return datetime.toISOString();
    };

    // Get the adjusted ISO strings with local offset
    const adjustedStartISOString = toLocalISOString(fecha, startTime);
    const adjustedEndISOString = toLocalISOString(fecha, endTime);

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                tratamiento: tratamiento,
                especialista: especialista,
                fecha: fecha,
                horario: [adjustedStartISOString, adjustedEndISOString],
                clienteId: clienteId
            })
        });

        if (!response.ok) {
            throw new Error('Failed to register appointment');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al registrar cita:', err.message);
        throw err;
    }
};

export const modifyAppointment = async (id, tratamiento, especialista, fecha, horario) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    const [startTime, endTime] = horario.split(" - ");
    const toLocalISOString = (date, time) => {
        const fullDatetimeString = `${date}T${time}:00`;
        const datetime = new Date(fullDatetimeString);
        return datetime.toISOString();
    };

    // Get the adjusted ISO strings with local offset
    const adjustedStartISOString = toLocalISOString(fecha, startTime);
    const adjustedEndISOString = toLocalISOString(fecha, endTime);

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/modify/${id}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                tratamiento: tratamiento,
                especialista: especialista,
                fecha: fecha,
                horario: [adjustedStartISOString, adjustedEndISOString]            
            })
        });

        if (!response.ok) {
            throw new Error('Failed to modify appointment');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al modificar cita:', err.message);
        throw err;
    }
};

export const fetchWorkingHours = async (especialistaId, fecha) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");
    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/${especialistaId}/working-hours?date=${fecha}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch working hours for ${fecha}`);
        }

        const data = await response.json();
        const workingHours = data.workingHours;

        return workingHours;

    } catch (error) {
        console.error('Error fetching working hours:', error);
        throw error;
    }
};

export const getAppointmentsByUserId = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/me`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch appointments');
        }

        const data = await response.json();
        return data.appointments;

    } catch (error) {
        console.error('Error fetching appointments:', error.message);
        throw error;
    }
};

export const getPastAppointmentsByUserId = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/history/me`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch past appointments');
        }

        const data = await response.json();
        return data.appointments;

    } catch (error) {
        console.error('Error fetching past appointments:', error.message);
        throw error;
    }
};

export const confirmBudget = async (id, budget) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/presupuesto/confirm/${id}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ presupuestoSeleccionado: budget })
        });

        if (!response.ok) {
            throw new Error('Failed to confirm budget');
        }

        const data = await response.json();
        return data.appointments;

    } catch (error) {
        console.error('Error confirming budget:', error.message);
        throw error;
    }
};


export const cancelAppointmentbyAdmin = async (appointmentId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/admin/cancel/${appointmentId}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to delete appointment');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error deleting appointment:', error.message);
        throw error;
    }
};

export const getAppointmentsBySpecialistId = async (especialistaId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/specialist/${especialistaId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch appointments for specialist');
        }

        const data = await response.json();
        
        return data.appointments;

    } catch (error) {
        console.error('Error fetching appointments for specialist:', error.message);
        throw error;
    }
};

export const getAppointmentById = async (appointmentId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/individual/${appointmentId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch appointment');
        }

        const data = await response.json();
        return data.appointment;

    } catch (error) {
        console.error('Error fetching appointment:', error.message);
        throw error;
    }
};

export const getUserHistory = async (id) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    const response = await fetch(`${REACT_APP_BFF_URL}/appointments/historial/user/${id}`, {
        method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

    if (!response.ok) {
        throw new Error("Error fetching anamnesis by appointment ID");
    }

    const data = await response.json();
    console.log(data)
    return data;
};

export const getAppointmentsByDayAndSpecialist = async (especialistaId, fecha) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/specialist/${especialistaId}/date?date=${fecha}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch appointments for specialist and day');
        }

        const data = await response.json();
        
        return data.appointments;

    } catch (error) {
        console.error('Error fetching appointments for specialist and day:', error.message);
        throw error;
    }
};

export const getAvailableHours = async (especialistaId, fecha) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/avaiblehours/${especialistaId}/${fecha}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch appointments for specialist and day');
        }

        const data = await response.json();
        
        return data;

    } catch (error) {
        console.error('Error fetching appointments for specialist and day:', error.message);
        throw error;
    }
};

export const getAllAppointments = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/all`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to fetch appointments: ${response.status} ${response.statusText} - ${errorText}`);
        }

        const data = await response.json();
        
        return data;

    } catch (error) {
        console.error('Error fetching appointments:', error.message);
        throw error;
    }
};

export const getAppointmentsByDay = async (fecha) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/day/${fecha}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch appointments for the given day');
        }

        const data = await response.json();
        
        return data.appointments;

    } catch (error) {
        console.error('Error fetching appointments for the given day:', error.message);
        throw error;
    }
};

export const cancelAppointment = async (appointmentId, userId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/cancel/${appointmentId}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ userId })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to cancel appointment');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error cancelling appointment:', error.message);
        throw error;
    }
};
export const updateAnamnesis = async (appointmentId, anamnesis) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/anamnesis/${appointmentId}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ anamnesis })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to update anamnesis');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error updating anamnesis:', error.message);
        throw error;
    }
};

export const getAnamnesisByAppointmentId = async (appointmentId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/anamnesis/${appointmentId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch anamnesis');
        }

        const data = await response.json();
        return data.anamnesis;

    } catch (error) {
        console.error('Error fetching anamnesis:', error.message);
        throw error;
    }
};

export const getPastAppointmentsBySpecialistId = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/specialist/history/me`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch past appointments for specialist');
        }

        const data = await response.json();
        return data.appointments;

    } catch (error) {
        console.error('Error fetching past appointments for specialist:', error.message);
        throw error;
    }
};

export const updateValoracion = async (appointmentId, formData) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/valoracion/${appointmentId}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include",
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Error al actualizar la valoración');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const getValoracionByAppointmentId = async (appointmentId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/valoracion/${appointmentId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include",
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Error al obtener la valoración');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const addPresupuestosToAppointment = async (appointmentId, presupuestoIndex, presupuesto) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");
    try {
        if (!presupuesto || typeof presupuesto !== 'object' || !presupuesto.montoTotal || !Array.isArray(presupuesto.tratamientos)) {
            throw new Error('Invalid presupuesto object');
        }
        
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/presupuestos/${appointmentId}/${presupuestoIndex}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ montoTotal: presupuesto.montoTotal, tratamientos: presupuesto.tratamientos })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to add presupuesto');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error adding presupuesto:', error.message);
        throw error;
    }
};


export const getPresupuestosByAppointmentId = async (appointmentId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/presupuestos/${appointmentId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch presupuestos');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching presupuestos:', error.message);
        throw error;
    }
};

export const getIncomes = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/appointments/incomes`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch presupuestos');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching presupuestos:', error.message);
        throw error;
    }
};

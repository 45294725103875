import React, { useEffect, useState } from 'react';
import { Button, Col, Input, message, Popconfirm, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { cancelAppointment, getAppointmentsByUserId } from '../services/AppointmentServices';
import { useUserId } from '../utils/Cookies';
import { getDentistName } from '../services/UserServices';

const AppointmentManage = () => {
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [professionals, setProfessionals] = useState({});
    const [searchText, setSearchText] = useState('');
    const userId = useUserId();

    useEffect(() => {
        const fetchAppointmentsAndProfessionals = async () => {
            try {
                const fetchedAppointments = await getAppointmentsByUserId(userId);
                const professionalIds = new Set();
                const mappedAppointments = fetchedAppointments
                    .filter(appointment => appointment.status !== 'Cancelada')
                    .map(appointment => {
                        professionalIds.add(appointment.participants[1]);
                        return {
                            _id: appointment._id,
                            fecha: appointment.date.day,
                            startTime: appointment.date.start_time,
                            endTime: appointment.date.end_time,
                            tratamiento: appointment.treatment,
                            especialista: appointment.participants[1],
                            status: appointment.status
                        };
                    });
                setAppointments(mappedAppointments);
    
                const professionalsMap = {};
                for (const id of professionalIds) {
                    try {
                        const professionalName = await getDentistName(id);
                        if (professionalName) {
                            professionalsMap[id] = professionalName;
                        }
                    } catch (error) {
                        console.error(`Error fetching user ${id} information:`, error);
                    }
                }
                setProfessionals(professionalsMap);
            } catch (error) {
                console.error('Error fetching appointments or professionals:', error);
            }
        };
        fetchAppointmentsAndProfessionals();
    }, [userId]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleDelete = async (key) => {
        try {
            await cancelAppointment(key, userId);
            setAppointments(appointments.filter(appointment => appointment._id !== key));
            message.success('Cita cancelada correctamente');
        } catch (error) {
            console.error('Error deleting appointment:', error);
            message.error('No se pudo cancelar la cita');
        }
    };

    const handleModify = async (appointmentId) => {
        try {
            navigate(`/app/cliente/modificar-cita/${appointmentId}`);
        } catch (error) {
            console.error('Error al modificar la cita:', error);
        }
    };

    const filteredAppointments = appointments.filter(appointment => 
        appointment.tratamiento.toLowerCase().includes(searchText.toLowerCase()) ||
        professionals[appointment.especialista]?.toLowerCase().includes(searchText.toLowerCase()) ||
        new Date(appointment.fecha).toISOString().split('T')[0].includes(searchText)
    );

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            width: 100,
            sorter: (a, b) => new Date(a.fecha) - new Date(b.fecha),
            render: (text) => new Date(text).toISOString().split('T')[0]
        },
        {
            title: 'Hora',
            dataIndex: 'startTime',
            width: 120,
            render: (text, record) => {
                const startTime = new Date(record.startTime).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                const endTime = new Date(record.endTime).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                return `${startTime} - ${endTime}`;
            },
        },
        {
            title: 'Tratamiento',
            dataIndex: 'tratamiento',
            width: 110,
        },
        {
            title: 'Profesional',
            dataIndex: 'especialista',
            width: 150,
            render: (text) => <span>{professionals[text] || text}</span>,
        },
        {
            title: '',
            dataIndex: '',
            width: 50,
            render: (_, record) => (
                <Popconfirm
                    title="¿Está seguro de cancelar este turno?"
                    onConfirm={() => handleDelete(record._id)}
                    okText="Sí"
                    cancelText="No"
                >
                    <Button type="link" danger icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
            ),
        },
        {
            title: 'Accion',
            key: 'accion',
            width: 120,
            render: (text, record) => {
                return (
                    <Button onClick={() => handleModify(record._id)}>
                        Mover
                    </Button>
                );
            }
        }
    ];

    const navigateToHome = () => {
        navigate(`/app`);
    };

    const navigateToAdd = () => {
        navigate(`/app/cliente/agendar`);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col xl={17} lg={20} sm={22} xs={22} className="recordCard">
                    <Row>
                        <h1 className="home-title">Estas son tus próximas citas</h1>
                    </Row>
                    <Row style={{ marginBottom: '16px' }}>
                        <Input
                            placeholder="Buscar"
                            value={searchText}
                            onChange={handleSearch}
                            allowClear
                        />
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={filteredAppointments}
                        className="tabla font-small"
                        showSorterTooltip={{
                            title: 'Haga clic para ordenar',
                        }}
                        pagination={false}
                        scroll={{
                            y: 240,
                        }}
                        rowKey="_id"
                    />
                    <Col span={24} className="inputCol">
                        <Row justify={'space-between'}>
                            <Col>
                                <Button className="volverButton" onClick={navigateToHome}>
                                    VOLVER
                                </Button>
                            </Col>
                            <Col>
                                <Button className="NuevaCitaButton" onClick={navigateToAdd}>
                                    NUEVA CITA +
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default AppointmentManage;

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, message, Button } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { getUserHistory } from '../services/AppointmentServices'; 
import { getClientName } from '../services/UserServices'; 

import moment from 'moment';

const ClientHistory = () => {
    const { id } = useParams(); 
    const [appointments, setAppointments] = useState([]);
    const [clientName, setClientName] = useState(''); 
    const navigate = useNavigate();

    useEffect(() => {
        loadUserHistory();
        loadClientName(); 
    }, [id]);

    const loadUserHistory = async () => {
        try {
            const anamnesis = await getUserHistory(id);
            setAppointments(anamnesis);
        } catch (error) {
            message.error('Error al cargar el historial del usuario');
            console.error('Error al cargar el historial:', error.message);
        }
    };

    const loadClientName = async () => {
        try {
            const name = await getClientName(id); 
            setClientName(name); 
        } catch (error) {
            message.error('Error al cargar el nombre del cliente');
            console.error('Error al cargar el nombre del cliente:', error.message);
        }
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={16} sm={24} xs={24} className="container-procedures">
                    <h1>Historial de Citas de {clientName}</h1>
                    <Row gutter={16}>
                        {appointments.length > 0 ? (
                            appointments.map((appointment, index) => (
                                <Col key={index} lg={8} sm={12} xs={24}>
                                    <Card
                                        className="appointment-card procedimiento-card" 
                                        title={`Fecha: ${moment(appointment.fecha).format('DD/MM/YYYY')}`}
                                        bordered={false}
                                    >
                                        <div><strong>Hora Inicio:</strong> {appointment.horaInicio}</div>
                                        <div><strong>Hora Fin:</strong> {appointment.horaFin}</div>
                                        <div><strong>Tratamiento:</strong> {appointment.tratamiento}</div>
                                        <div><strong>Observaciones:</strong> {appointment.observaciones}</div>
                                    </Card>
                                </Col>
                            ))
                        ) : (
                            <p>No hay citas confirmadas para mostrar.</p>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row justify="end">
                <Button
                    type="default"
                    onClick={() => navigate(-1)}
                    className="volver-button volver-button2" 
                >
                    Volver
                </Button>
            </Row>
        </div>
    );
};

export default ClientHistory;
